// Import theme colors
@import "./components/colors";

// Import owl styles
@import url(https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css);

// Custom styles
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    // padding: 1% !important;
    background-color: lighten($colorInverse, 70%);
}
.owl-theme .owl-nav [class*="owl-"] {
    &:hover {
        background: $colorInverse;
    }
}

.owl-prev,
.owl-next {
    width: 60px;
    height: 60px;
    border-radius: 0 !important;
    i {
        transform: scale(2, 2);
    }
}
@media only screen and (max-width: 600px) {
    .owl-carousel {
        .owl-nav {
            button {
                &.owl-prev {
                    margin-right: 5px;
                }
                &.owl-next {
                    margin-left: 5px;
                }
            }
        }
    }
}